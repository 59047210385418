import { graphql } from 'gatsby'
import IndexPage from './all/index'

export default IndexPage

//NOTE: copied from all/index.js
export const query = graphql`
  query($language: String!) {
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^pages\\.home/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
